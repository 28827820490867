.App {
  text-align: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.Avatar {
  margin-top: 15%;
  border-radius: 50%;
  vertical-align: middle;
}

.App-header {
  background-color: #222;
  height: 70%;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: 2em;
}

a,
a:active,
a:visited {
  text-decoration: none;
  color: #0059ff;
}

a:hover {
  color: blue;
}